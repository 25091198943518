.footer-div {
	display: flex;
}

.footer-btn {
	width: 100%;
	margin: 0.5rem;
}

.nav-button {
	height: 3rem;
	font-size: medium;
}

.view-button {
	height: 3rem;
}

.result-icon {
	width: 100px;
	height: 100px;
	color: skyblue;
}



.result-body {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 4rem;
}

.total-body {
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--ion-background-color);
	padding: 1rem;
	border: 0.5rem solid var(--ion-color-warning);
	color: var(--ion-color-warning);
}


.result-total {
	font-size: 50px;
	padding-bottom: 1rem;
	color: var(--ion-text-color);
}

.result-slash {
	font-size: 2rem;
	padding: 0.2rem;
}

.result-outof {
	padding-top: 1rem;
}

.ion-padding {
	z-index: 11;
}

.i-frame {
	width: 100%;
	height: 99%;
}