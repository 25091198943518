* {
    font-family: monospace !important;
}

.load-banner {
    margin-bottom: 3.2rem;
}
.hide-banner {
    margin-bottom: none;
}
