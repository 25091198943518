.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-result {
  display: flex;
  justify-content: center;
  align-items: center;
}


.custom-search {
  --border-radius: 25px;
  --box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.14), 0 0px 0px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}